export default {
  SET_SKILLS(state, skills) {
    state.skills = skills;
  },
  SET_SKILL(state, skill) {
    state.skill = skill;
  },
  SET_MCQ(state, mcq) {
    state.mcq = mcq;
  },
  SET_ASSESSMENT(state, assessment) {
     state.assessment = assessment
  },
  SET_TAGS(state, tags) {
    state.tags = tags;
  },
  SET_MCQ_SCORES(state, scores) {
    state.mcqScores = scores
  }
};
