import axios from "@/axios";

export default {
    signin(payload) {
        return axios.post("/auth/signin", payload)
    },
    fetchUser() {
        return axios.get("/auth/user")
    },
    requestPasswordReset(email) {
        return axios.post("/auth/request-password-reset", { email: email })
    },
    resetPassword(payload) {
        return axios.post("/auth/reset-password", payload)
    },
    updateAvailability(status) {
        return axios.patch("/dashboard/availability", { status: status })
    },
    updateProfile(payload) {
        return axios.patch("/profile", payload)
    },
    updateNextOfKin(payload) {
        return axios.patch("/profile/next-of-kin", payload)
    },
    updateResume(payload) {
        return axios.post("/profile/resume", payload, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
}
