export default {
    isUserLoggedIn(state, getters, rootState) {
        const user = !!rootState.user
        const accessToken = !!state.accessToken

        return (user && accessToken) && (getters.lastActive !== 'never' && (getters.lastActive < 24 || state.rememberMe))
    },
    lastActive(state) {
        if (state.timestamp === null) {
            return 'never'
        }
        return (Date.now() - state.timestamp) / 36e5
    },
    nextOfKin: (state, getters, rootState) => {
        return rootState.user.next_of_kin || {
            name: '',
            relationship: '',
            email: '',
            phone: '',
            address: ''
        }
    },
    bankDetails: (state, getters, rootState) => {
        return rootState.user.bank || {
        }
    },
    resume: (state, getters, rootState) => {
        return ''
    },
    nextOfKinIsBusy: (state) => {
        return state.isBusy.nextOfKin
    },
    profileIsBusy: (state) => {
        return state.isBusy.profile
    }

}