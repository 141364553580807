export default {
  skills: [],
  skill: {
    category: {

    },
    name: '',
    assessments: []
  },
  mcq: {
    skill: {
      name: ''
    },
    questions: 0,
    attempted_questions: 0,
    current_question: {},
    attempted: 1,
    status: 1
  },
  assessment: {
     has_skill: false,
     skill: {
      name: '',
      assessments: []
     },
     latest_mcq: {
       test_taken: null
     },
     assessed: [],
     ongoing_test: {
       description: ''
     },
     latest_test: {
      description: ''
     }
  },
  mcqScores: {
    scores: [],
    in_top: 100
  },
  tags: [],
};
