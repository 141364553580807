export default {
    TOGGLE_ONLINE(state, value) {
        state.online = value
    },
    UPDATE_USER_INFO(state, payload) {
        state.user = payload
        localStorage.setItem("userInfo", JSON.stringify(payload))
    },
    SET_ERROR_STATUS(state, status) {
        state.errorStatus = status
    },
    SET_PAGE_INFORMATION(state, pageInfo) {
        state.pageTitle = pageInfo.title
        state.pageDescription = pageInfo.description
    }
}