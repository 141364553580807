export default {
  formattedSkills(state) {
    return state.skills.map((skill) => {
      return { ...skill, ...{ tags: skill.tags.map((tag) => tag.id) } };
    });
  },
  assessmentProgress(state) {
    return state.skill.assessments;
  },
  selectedSkill(state) {
    return state.skill;
  },
};
