import Echo from "laravel-echo";

window.Pusher = require("pusher-js");

const PUSHER_OPTIONS = {
  broadcaster: "pusher",
  auth: {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
  },
  authEndpoint: process.env.VUE_APP_PUSHER_AUTH_ENDPOINT,
  key: process.env.VUE_APP_PUSHER_KEY,
  wsHost: process.env.VUE_APP_PUSHER_HOST,
  wsPort: process.env.VUE_APP_PUSHER_PORT,
  wssPort: process.env.VUE_APP_PUSHER_PORT,
  forceTLS: true,
  encrypted: true,
  enableLogging: true,
  disableStats: true,
  enabledTransports: ["ws", "wss"],
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
};

window.Echo = new Echo(PUSHER_OPTIONS);
