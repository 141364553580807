import auth from "@/http/requests/auth";

export default {
  signin({ commit }, payload) {
    return new Promise((resolve, reject) => {
      auth
        .signin(payload)
        .then((response) => {
          commit("SET_BEARER", response.data.data.token.access_token);
          commit("SET_REMEMBER_ME", payload.rememberMe);
          commit("UPDATE_USER_INFO", response.data.data.user, { root: true });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  signOut({ commit }) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userInfo");
    commit("SIGN_OUT");
  },
  fetchUser({ commit }) {
    return new Promise((resolve, reject) => {
      auth
        .fetchUser()
        .then((response) => {
          commit("UPDATE_USER_INFO", response.data.data, { root: true });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateAvailability({ commit }, status) {
    return new Promise((resolve, reject) => {
      auth
        .updateAvailability(status)
        .then((response) => {
          commit("UPDATE_USER_INFO", response.data.data, { root: true });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  requestPasswordReset({}, email) {
    return new Promise((resolve, reject) => {
      auth
        .requestPasswordReset(email)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  resetPassword({}, payload) {
    return new Promise((resolve, reject) => {
      auth
        .resetPassword(payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
    })
    },
    updateProfile({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('SET_BUSY_STATE', { profile: true })
            auth.updateProfile(payload)
                .then(response => {
                    console.log(response)
                    commit("UPDATE_USER_INFO", response.data.data, { root: true })
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
                .finally(() => {
                    commit('SET_BUSY_STATE', { profile: false })
                })
        })
    },
    updateNextOfKin({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('SET_BUSY_STATE', { nextOfKin: true })
            auth.updateNextOfKin(payload)
                .then(response => {
                    commit("UPDATE_USER_INFO", response.data.data, { root: true })
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
                .finally(() => {
                    commit('SET_BUSY_STATE', { nextOfKin: false })
                })
        })
    },
    updateResume({ commit }, payload) {
        return new Promise((resolve, reject) => {
            auth.updateResume(payload)
                .then(response => {
                    commit("UPDATE_USER_INFO", response.data.data, { root: true })
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
}
